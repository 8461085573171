import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() image: string;
  @Input() title: string;
  @Input() description: string;
  @Input() fill: string;
  @Input() color: string;
  

  getFilling(fill:string){
    return "circle-fill-"+fill;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
