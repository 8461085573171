import { Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { Cookie } from '../models/cookie.model';

@Injectable({
  providedIn: 'root'
})

export class ControlSystemService {
    private languages = ['en','es'];
    private cookie: Cookie = new Cookie("dark");
    

    constructor(private translateService: TranslateService) {
        //Ponemos como idioma por defecto el idioma del navegador o ingles en caso de que no este disponible
        let lang = this.languages.includes(translateService.getBrowserLang()) ? translateService.getBrowserLang() : this.languages[0];
        translateService.setDefaultLang(lang);
        translateService.use(lang);
        this.cookie.lang = lang;

        //Comprobamos si hay cookie guardada y la cargamos
        let cookie = this.getCookie();
        if (cookie) {
            this.fetchCookieData(cookie);
        }
    }

    /**
     * Cambia el lenguaje al especificado
     * @param code Código del idioma ('es', 'en'...)
     */
    setLanguage(code: string) {
        this.translateService.use(code);
        this.cookie.lang = code;
        this.setCookie();
    }

    changeLanguage() {
        if(this.translateService.getDefaultLang() != 'en'){
            if(this.getLang() != 'en'){
                this.setLanguage('en');
            }else{
                this.setLanguage(this.translateService.getDefaultLang());
            }
        }else{
            if(this.getLang() != 'en'){
                this.setLanguage('en');
            }else{
                this.setLanguage('es');
            }
        }
    }

    /**
     * Cambiamos el Theme de la página
     * @param theme Nombre de la clase del theme en themes.scss
     */
    setTheme(theme: string) {
        this.cookie.theme = theme;
        document.documentElement.setAttribute('theme', this.cookie.theme);
        this.setCookie();
    }

    changeTheme() {
        this.setTheme(this.cookie.theme == 'light' ? 'dark' : 'light');
    }

    lightActive(){
        return this.cookie.theme == 'light';
    }

    englishActive(){
        return this.getLang() == 'en';
    }

    /**
     * Devolvemos el nombre del theme actual
     */
    getThemeName() {
        return "THEMES." + this.cookie.theme.toUpperCase();
    }

    getTheme() {
        return this.cookie.theme.toLowerCase();
    }

    checkTheme(name: string) {
        return this.getTheme() === name;
    }

    /**
     * Devolvemos el codigo del idioma actual
     */
    getLangName() {
        return "LANGUAGES." + this.translateService.currentLang.toUpperCase();
    }

    getLang() {
        return this.translateService.currentLang.toLowerCase();
    }

    checkLang(name: string) {
        return this.getLang() === name;
    }

    /**
     * Encripta los datos
     * @param data String de datos a encriptar
     */
    encrypt(data : string) {

        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), "We4reIntar3").toString();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Desencripta los datos
     * @param data String de datos a desencriptar
     */
    decrypt(data : string) {

        try {
            const bytes = CryptoJS.AES.decrypt(data, "We4reIntar3");
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Devuelve la fecha en formato Español dd/mm/aaaa
     * @param data Fecha a transformar
     */
    getLocalDate(date : Date) {
        return new Date(date).toLocaleDateString("es-ES");
    }

    /**
     * Devuelve la Cookie almacenada
     */
    getCookie() {
        var name = "CFG=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    /**
     * Guarda la Cookie actual
     */
    setCookie() {
        //document.cookie = "CFG=" + this.encrypt(JSON.stringify(this.cookie)) + ";domain=intare.es;path=/";
        document.cookie = "CFG=" + this.encrypt(JSON.stringify(this.cookie)) + ";path=/"; //Testeo
    }

    /**
     * Carga la cookie almacenada
     * @param cookie
     */
    fetchCookieData(cookie) {
        cookie = JSON.parse(this.decrypt(cookie));

        this.cookie.theme = cookie.hasOwnProperty('theme') ? cookie['theme'] : this.cookie.theme;
        this.setTheme(this.cookie.theme);
        this.cookie.lang = cookie.hasOwnProperty('lang') ? cookie['lang'] : this.cookie.lang;
        this.setLanguage(this.cookie.lang);
    }

}
