import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  @ViewChild("modalContent", { read: ViewContainerRef, static: false })
  set container(container: ViewContainerRef) {
    if (!container) return;
    setTimeout(() => {
      container.clear();
      const componentFactory = this.resolver.resolveComponentFactory(
        this.modalService.getBody()
      );
      container.createComponent(componentFactory);
    });
  }

  constructor(
    private modalService: ModalService,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.modalService.clear();
  }

  submitModal() {
    this.modalService.submit();
    this.modalService.clear();
  }

  getModalService() {
    return this.modalService;
  }
}
