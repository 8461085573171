<nav class="navbar navbar-expand-md primary-color fixed-top pb-0 pt-0 pl-2 pr-2">
  <a class="navbar-brand">
    <!--  Logotipo de Intare circular  -->
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" routerLink="/"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">
      <!--<path d="M471.5 1c-109.1 9.5-206.2 49.3-290 119.1-13.6 11.4-44.5 41.9-56.6 55.9-20.8 24.2-43.2 55.7-57.7 81C29 323.9 6.9 396.8.9 475c-1.5 20-.6 73.8 1.5 91.5 3.4 29.1 6.7 47.2 13.2 72.5C34.4 713.2 69 780.8 119.4 841.5c12.6 15.1 47.5 50.1 62.6 62.7 80.2 66.7 172 105.7 277 117.4 18.7 2.1 67.6 2.9 87.5 1.5 111.3-8.1 209.9-47.9 295.6-119.2 15.9-13.3 48.5-45.9 61.8-61.8 71.3-85.7 111.1-184.3 119.2-295.6 1.4-19.9.6-68.8-1.5-87.5-11.8-105-50.7-196.8-117.4-277-12.6-15.1-47.6-50-62.7-62.6-33.9-28.1-64.9-48.2-104-67.4-55-27-105.9-41.8-171-49.6-15.1-1.9-78.9-2.8-95-1.4zM556 45.6c203.1 18.6 372.7 170.1 414.4 370.1 7.7 37.1 10.1 63.6 9.3 105.6-.7 40.5-4.1 67-13.3 104-8.7 34.7-19 62.6-35 94.7-23.8 47.7-51.2 85.9-88.4 123-37.1 37.2-75.3 64.6-123 88.4-49 24.4-98.3 39-155.5 46.2-17.5 2.2-68.7 3-88.2 1.5-130.3-10.4-248.4-72.8-330-174.6C18.4 645 9 420.2 123.2 250.8 205.5 128.6 338.9 52.6 486 44.1c19.1-1.1 48.1-.5 70 1.5z" />-->
      <circle cx="50%" cy="50%" r="46%" stroke-width="4%" />
      <path d="M510 129.2c0 2.1-5.4 37.3-7 46.1-3.7 19.4-11.6 37.9-24.6 57.3-3.5 5.3-6.4 9.8-6.4 10 0 .2 1.9.4 4.1.4 6.4 0 12 2.1 15.9 6 8 8 8.1 23.8.1 35.3-1.7 2.5-3.1 5.3-3.1 6.3s1.2 4.7 2.6 8.3c4.2 10.4 5.3 20.8 6 55.6.9 43.5-1 66.3-7.7 93.5-3.7 14.9-9.3 29.9-14.9 39.4-3.9 6.7-4.3 8.6-1.5 8.6 3.5 0 10.6 12.2 13 22.2 3.9 16.5.8 30-11.9 53.2-2.7 5-4.4 9.2-3.9 9.3 3.7 1.3 11 7.3 13.4 11.2 3.8 5.9 4.4 10 3 18.9-1.5 10.1-4.4 16.8-14.5 33.5-13.1 21.9-19.7 39.4-21.1 55.9-1.2 14 3.1 24.9 22.4 56.6 19.4 31.9 24.5 44.2 28 67.8 1.8 11.9 8.1 65.4 8.1 68.6 0 .6.2.9.4.7.3-.3 1.4-9.2 2.6-19.9 3-27.7 5.8-49.9 7.6-59.2 3-16.1 10.8-32.9 27.8-60.3 18.4-29.6 22.7-41.7 20.6-58-1.9-15.6-10.2-36-22.1-54.5-6.7-10.5-10.7-19.4-12.5-28.1-2.1-9.7-1.4-15.7 2.2-21.6 2.6-4.1 9.7-10.2 13.5-11.5.8-.3-.6-3.7-3.8-9.5-12.4-21.9-15.7-36.6-11.8-53.2 2.5-10.5 9.4-22.1 13.1-22.1 2.4 0 2.5-2 .4-5-6.2-8.7-14.2-30-18.4-49-5.2-23.7-6.1-34.2-6-76.5 0-41.4.8-53.3 4.5-63.4 4.6-12.3 4.6-12.3.9-17.6-4-5.8-6-12.1-6-18.8 0-14 8.4-22.7 21.9-22.7 2.2 0 4.1-.1 4.1-.3 0-.2-3-4.8-6.6-10.2-11.6-17.4-20.4-37.2-23.8-53.7-.8-4-2.9-16.7-4.6-28.2-1.7-11.6-3.3-21.2-3.5-21.5-.3-.2-.5-.2-.5.1z" />
      <g class="spin">
        <path d="M650.1 505.6c-1.7 10.3-7.4 21.4-15.1 29.2-3.6 3.7-12 10.2-20.8 15.9-30 19.6-40.2 34.8-43.1 64.2-.5 5.3-.6 11.3-.3 13.5l.7 3.9 3.2-6.9c6.2-13.5 11.5-17.7 34.5-27.4 16.3-6.8 21.3-10 25.9-16.3 7.2-9.6 13.7-27.9 16.4-45.7 1.6-10.2 2-36.5.7-37.4-.5-.3-1.4 2.9-2.1 7z" />
        <path d="M368 513.2c0 22 3.8 40.9 11.5 57.3 4.1 8.9 8.4 14.7 13.6 18.4 2.2 1.6 11 5.9 19.6 9.6 22.5 9.5 26.2 12.5 33.6 26.7l3.7 7.3-.4-13.5c-.5-15.5-2.6-24.3-8.3-35.3-5.8-11.1-14.3-19.2-33.1-31.9-9.6-6.4-19.2-13.7-22.5-17.1-6.9-7.2-13.1-19.2-14.9-29-.7-3.7-1.6-6.7-2-6.7-.5 0-.8 6.4-.8 14.2z" />
        <path d="M380 642.7c0 2.5 7.2 12.2 11.7 15.9 7.2 5.9 14.3 8.3 27 9.1 6.9.4 11.7 1.2 13.9 2.4 3.5 1.7 3.5 1.7 2.9-.4-.4-1.2-1.8-4.6-3.2-7.5-3.9-8.3-12.5-12-31.3-13.7-9.4-.8-14.7-2.2-18.2-4.7-1.6-1.1-2.8-1.5-2.8-1.1z" />
        <path d="M639.6 642.8c-1.4 2.3-8.5 4.4-19.5 5.7-21.2 2.5-27.8 5.4-31.6 13.9-1.3 2.8-2.6 6.1-2.9 7.3l-.7 2.1 3.8-1.9c2.3-1.2 5.8-1.9 9.3-1.9 15.2-.1 25.9-3.8 33.9-11.8 4.6-4.6 9.8-12.8 8.8-13.9-.3-.2-.8 0-1.1.5z" />
      </g>
    </svg>

  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <!--  Icono de Hamburgesa  -->
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" class="hamburger">
      <path d="M9,2 L1,2" class="p1" />
      <path d="M1,5 L9,5" class="p2" />
      <path d="M9,8 L1,8" class="p3" />
    </svg>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown" type="hover" routerLink="/services" routerLinkActive="active">
        <a [innerHTML]="'NAVBAR.NAV-1.TITLE' | translate" class="nav-link dropdown-toggle" id="servicesDropdown" aria-haspopup="true" aria-expanded="false">
          
        </a>
        <ul class="dropdown-menu" aria-labelledby="servicesDropdown">
          <a translate class="dropdown-item" routerLink="/development/apps" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">NAVBAR.NAV-1.SUBNAV.1</a>
          <a translate class="dropdown-item" routerLink="/development/software" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">NAVBAR.NAV-1.SUBNAV.2</a>
          <a translate class="dropdown-item" routerLink="/development/games" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">NAVBAR.NAV-1.SUBNAV.3</a>
          <a translate class="dropdown-item" routerLink="/development/web" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">NAVBAR.NAV-1.SUBNAV.4</a>
        </ul>
      </li>
      <li class="nav-item" routerLink="/formation"  routerLinkActive="active">
        <a translate class="nav-link">NAVBAR.NAV-2.TITLE<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item" routerLink="/about"  routerLinkActive="active">
        <a translate class="nav-link">NAVBAR.NAV-3.TITLE<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item" routerLink="/contact"  routerLinkActive="active">
        <a translate class="nav-link" >NAVBAR.NAV-4.TITLE</a>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item dropdown" type="hover">
        <a translate class="nav-link dropdown-toggle" id="optionsDropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" preserveAspectRatio="xMaxYMin meet">
            <path d="M14 8.77v-1.6l-1.94-.64-.45-1.09.88-1.84-1.13-1.13-1.81.91-1.09-.45-.69-1.92h-1.6l-.63 1.94-1.11.45-1.84-.88-1.13 1.13.91 1.81-.45 1.09L0 7.23v1.59l1.94.64.45 1.09-.88 1.84 1.13 1.13 1.81-.91 1.09.45.69 1.92h1.59l.63-1.94 1.11-.45 1.84.88 1.13-1.13-.92-1.81.47-1.09L14 8.75v.02zM7 11c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
          </svg>
        </a>
        <ul class="dropdown-menu dropdown-menu-right pb-0" aria-labelledby="optionsDropdown">
          <div class="d-flex">
            <div class="col-6 pl-2 pr-1">
              <div [attr.class]="'quick-button' + (this.controlSystem.lightActive() ? ' active' : '')">
                  <palette [category]="'icon'" [name]="'sun'" (click)="this.controlSystem.changeTheme()">{{ 'QUICK-BUTTON.LIGHT' | translate }} {{this.controlSystem.lightActive() ? "ON" : "OFF"}}</palette>
              </div>
            </div>
            <div class="col-6 pr-2 pl-1">
              <div [attr.class]="'quick-button' + (this.controlSystem.englishActive() ? ' active' : '')">
                <palette [category]="'icon'" [name]="'planet'" (click)="this.controlSystem.changeLanguage()">{{ 'QUICK-BUTTON.LANGUAGE' | translate }} {{this.controlSystem.englishActive() ? "ON" : "OFF"}}</palette>
              </div>
            </div>
            
            <!--
            <div class=" col-6 dropdown">
              <a class="dropdown-item dropdown-toggle pr-0 pl-0" href="#" id="themeDropdown" role="button" data-toggle="submenu" aria-haspopup="true" aria-expanded="false" data-target="themeDropdown">
                <palette [category]="'theme'" [name]="this.controlSystem.getTheme()">{{ this.controlSystem.getThemeName() | translate }}</palette>
              </a>
              <div class="dropdown-menu" aria-labelledby="themeDropdown">
                <palette *ngIf="!this.controlSystem.checkTheme('light')" class="dropdown-item" [category]="'theme'" [name]="'light'" (click)="this.controlSystem.setTheme('light')">{{ 'THEMES.LIGHT' | translate }}</palette>
                <palette *ngIf="!this.controlSystem.checkTheme('dark')" class="dropdown-item" [category]="'theme'" [name]="'dark'" (click)="this.controlSystem.setTheme('dark')">{{ 'THEMES.DARK' | translate }}</palette>
              </div>
            </div>
            <div class=" col-6 dropdown">
              <a class="dropdown-item dropdown-toggle pl-0" href="#" id="flagDropdown" role="button" data-toggle="submenu" aria-haspopup="true" aria-expanded="false">
                <palette [category]="'flag'" [name]="this.controlSystem.getLang()">{{ this.controlSystem.getLangName() | translate }}</palette>
              </a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="flagDropdown">
                <palette *ngIf="!this.controlSystem.checkLang('es')" class="dropdown-item" [category]="'flag'" [name]="'es'" (click)="this.controlSystem.setLanguage('es')">{{ 'LANGUAGES.ES' | translate }}</palette>
                <palette *ngIf="!this.controlSystem.checkLang('en')" class="dropdown-item" [category]="'flag'" [name]="'en'" (click)="this.controlSystem.setLanguage('en')">{{ 'LANGUAGES.EN' | translate }}</palette>
              </div>
            </div>-->
          </div>
        </ul>
      </li>
    </ul>
    
  </div>
</nav>
