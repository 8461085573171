import { Component, OnInit } from "@angular/core";
import * as Parallax from "parallax-js";
import { ConstructionComponent } from "src/app/modals/construction/construction.component";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.modalService.setContent(ConstructionComponent);

    const scene = document.getElementsByClassName("parallax");
    for (var i = 0; i < scene.length; i++) {
      new Parallax(scene[i]);
    }
  }
}
