import { Injectable } from "@angular/core";
import { Modal } from "../models/modal.model";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private modal: Modal = {} as Modal;

  constructor() {}

  setContent(
    body: any,
    title: string | undefined = undefined,
    onSubmit?: () => void,
    canBeClosed: boolean = true
  ) {
    this.modal.body = body;
    this.modal.title = title;
    this.modal.onSubmit = onSubmit ? onSubmit : undefined;
    this.modal.canBeClosed = canBeClosed;
  }

  clear() {
    this.modal.body = undefined;
    this.modal.title = undefined;
    this.modal.onSubmit = undefined;
    this.modal.canBeClosed = true;
  }

  hasTitle() {
    return this.modal.title ? true : false;
  }

  hasSubmit() {
    return this.modal.onSubmit ? true : false;
  }

  getBody() {
    return this.modal.body;
  }

  getTitle() {
    return this.modal.title;
  }

  setTitle(title: string | undefined) {
    this.modal.title = title;
  }

  submit() {
    if (this.modal.onSubmit) {
      this.modal.onSubmit();
    }
  }

  getCanBeClosed() {
    return this.modal.canBeClosed;
  }

  setCanBeClosed(value: boolean) {
    this.modal.canBeClosed = value;
  }
}
