<div
  class="modal fade show d-block"
  style="background-color: #000000aa"
  id="exampleModalCenter"
  role="dialog"
  aria-hidden="false"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content bg-modal">
      <div class="modal-header">
        <h1
          translate
          class="modal-title w-100"
          [class.text-center]="!getModalService().getCanBeClosed()"
          *ngIf="getModalService().hasTitle()"
        >
          {{ getModalService().getTitle() }}
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          *ngIf="getModalService().getCanBeClosed()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"><ng-container #modalContent></ng-container></div>
      <div
        class="modal-footer border-0"
        *ngIf="getModalService().hasSubmit()"
      ></div>
    </div>
  </div>
</div>
