<div class="row">
  <div class="col-4 pr-4 pl-4">
    <div class="tools d-flex justify-content-around">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.8 112">
          <path
            fill="none"
            stroke="var(--product-colorB)"
            stroke-width="4px"
            d="M31.2,110a7.6,7.6,0,0,1-5-2L23,105.2l-9.7-8.5C6.1,90.5,2.3,84.5,2,79a9.9,9.9,0,0,1,2.3-7.2l5.3-6.2,8.6-10a7.9,7.9,0,0,1,4.4-2.5L47.3,27,69,4.1A6,6,0,0,1,73.6,2a6.8,6.8,0,0,1,4.5,1.8l9.2,8.3L54.8,77.6A7.1,7.1,0,0,1,57,82.3c.1,3.2-2.1,5.5-2.9,6.4H54a8.1,8.1,0,0,1-6,3,7,7,0,0,1-4.5-1.7L22.4,71.9c2.2,2.1,19.4,17.4,20.4,19a4.3,4.3,0,0,1,.4.7c3.5,5.4-1.5,11-3.9,13.7l-.5.6C36.5,108.6,33.9,110,31.2,110Z"
          />
          <path
            fill="var(--product-colorB)"
            d="M31.2,105a2.5,2.5,0,0,1-1.7-.8l-3.2-2.7-9.8-8.6C8.9,86.3,7.2,81.6,7,78.8a5.3,5.3,0,0,1,1-3.7L22,58.9a1.9,1.9,0,0,1,1.6-.8h.5l.5.2L50.9,30.4,72.6,7.5a1.4,1.4,0,0,1,1-.5,1.8,1.8,0,0,1,1.1.5l6.4,5.8L48.6,78.9,51.1,81a2.4,2.4,0,0,1,.9,1.5c0,1.1-1.1,2.3-1.5,2.8l-.2.2A3.8,3.8,0,0,1,48,86.8a2.1,2.1,0,0,1-1.2-.5L29.2,71.1c-2.6-2.3-5-3.5-6.9-3.5a3.4,3.4,0,0,0-2.8,1.3.8.8,0,0,0-.4.4,3.2,3.2,0,0,0-1.3,2.5c.1,1.7,1.6,3.7,5.4,6.9C36.5,90.3,37.1,91.1,38.5,93.5l.5.8c1.4,2.2-1.6,5.7-3.4,7.7l-.6.6C33.7,104.1,32.4,105,31.2,105Z"
          />
        </svg>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.9 115.5">
          <path
            fill="none"
            stroke="var(--product-colorC)"
            stroke-width="4px"
            d="M20.6,113.5a11.9,11.9,0,0,1-5.2-1.4l-1.5-.8c-3.2-1.6-6.5-3.3-8.4-5-.1-.2-.3-.3-.4-.4A7.4,7.4,0,0,1,2,100.8c-.2-2.6,1.4-4.7,2.6-6.1A84.5,84.5,0,0,0,14.6,80l2.1-4.3c.7-1.3,6.5-13.5,9.3-17.1l5.1-6.7c2.8-3.8,4.5-6.1,5.3-7a5.3,5.3,0,0,1,3.3-2l9-14.6.3-1.6c.5-2.4.4-5.3-.1-6s-1.8-1.8-3.9-1.8a26.4,26.4,0,0,0-6.3,1h0l-11.4,3-2.3.3a5.8,5.8,0,0,1-5.8-4c-.3-.9-.8-4.4,3.7-7.2H23C24.8,10.9,40.6,2,50.4,2h.8c9.5.5,15.3,1.9,21.5,5.3a16.4,16.4,0,0,1,6.5,6.5,5.4,5.4,0,0,0,2.2,2.5A15.5,15.5,0,0,0,87,17.8a13.9,13.9,0,0,1,5.3,1.4c7.4,4.3,5.8,8.9,4.7,10.8l-5,8.6a6.9,6.9,0,0,1-6.1,3.9,7.1,7.1,0,0,1-3.7-1l-3.1-1.8a9.2,9.2,0,0,1-3.4-4.3l-.8-1.5h-.3a7.6,7.6,0,0,0-3.9-1.4c-1.7,0-3.5,1.1-5.5,3.2l-.8.8L55.5,52.5a6.1,6.1,0,0,1-.1,4.6l-1.6,3.6a124.8,124.8,0,0,1-5.5,12.2c-1.1,2-4.3,6.8-7.4,11.5s-4.6,7-5.1,7.9c-1.7,2.9-7.4,13.6-8,15.7A7.4,7.4,0,0,1,20.6,113.5Z"
          />
          <path
            fill="var(--product-colorC)"
            d="M20.6,108.5a7.5,7.5,0,0,1-2.9-.8l-1.5-.8c-2.8-1.5-6-3.1-7.4-4.4l-.5-.4c-.8-.7-1.3-1.1-1.3-1.6s.2-1.1,1.4-2.5c3-3.4,8.6-11.3,10.7-15.8l2.1-4.3c.1-.1,6.3-13,8.8-16.3l5.1-6.7c2.2-2.9,4.4-6,5.1-6.8a.7.7,0,0,1,.6-.3h.7l.8.4L55,27.5l-.9-.5c.3-2,.9-7.2-1.6-9.8A9.3,9.3,0,0,0,45,13.9,36.4,36.4,0,0,0,37.5,15L25.9,18.1H25c-.7,0-.9-.2-1-.3s.3-.9,1.5-1.6S41.8,7,50.4,7h.5c8.7.5,14,1.7,19.4,4.7a11.1,11.1,0,0,1,4.6,4.6,9.7,9.7,0,0,0,3.9,4.3,18.6,18.6,0,0,0,7.6,2.1,11.3,11.3,0,0,1,3.4.8c3.9,2.3,3.2,3.5,2.9,4-1.3,2.1-5.1,8.7-5.1,8.7a2,2,0,0,1-1.7,1.3,1.9,1.9,0,0,1-1.2-.4l-3.1-1.8c-.5-.2-.9-1.1-1.3-2s-1.4-2.8-2.8-3.6l-.3-.2a12.4,12.4,0,0,0-6.5-2.1c-3,0-5.9,1.4-8.6,4.2l-.6-.3L49.7,52.7l.5.6c1,.9.7,1.5.6,1.7l-1.6,3.8c-1.5,3.7-3.9,9.3-5.2,11.6s-4.3,6.9-7.2,11.3-4.7,7.1-5.3,8.1-7.7,13.9-8.5,16.8A2.3,2.3,0,0,1,20.6,108.5Z"
          />
        </svg>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.4 119.5">
          <path
            fill="none"
            stroke="var(--product-colorA)"
            stroke-width="4px"
            d="M24.2,117.5c-3.7,0-8.1-1.5-12.2-4s-8.9-7.7-9.8-11.8a7.7,7.7,0,0,1,1-6L39,40.1A28.2,28.2,0,0,1,41.6,15,27.8,27.8,0,0,1,65.3,2h2.6l6.5.6L62.5,21.5c.2.8,1.6,3.1,4.9,5.2A13.3,13.3,0,0,0,73.8,29h.5L86.2,10.3l3.3,5.6A28,28,0,0,1,67.9,58l-36,55.9C30.4,116.2,27.7,117.5,24.2,117.5Z"
          />
          <path
            fill="var(--product-colorA)"
            d="M24.2,112.5a18.7,18.7,0,0,1-9.5-3.3c-4-2.5-7-5.9-7.6-8.6a2.7,2.7,0,0,1,.3-2.2L44.7,40.5l-.2-.5a22.7,22.7,0,0,1,1.3-22.3A23.1,23.1,0,0,1,65.3,7h.5L58,19.1c-1.9,3,1.1,8.2,6.7,11.8A17.7,17.7,0,0,0,73.8,34a5.3,5.3,0,0,0,4.5-1.9L86,19.9A23.2,23.2,0,0,1,65.7,53.2h-.6l-37.4,58C27.1,112.1,25.9,112.5,24.2,112.5Z"
          />
        </svg>
      </div>
    </div>
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
    >
      <!--<path d="M471.5 1c-109.1 9.5-206.2 49.3-290 119.1-13.6 11.4-44.5 41.9-56.6 55.9-20.8 24.2-43.2 55.7-57.7 81C29 323.9 6.9 396.8.9 475c-1.5 20-.6 73.8 1.5 91.5 3.4 29.1 6.7 47.2 13.2 72.5C34.4 713.2 69 780.8 119.4 841.5c12.6 15.1 47.5 50.1 62.6 62.7 80.2 66.7 172 105.7 277 117.4 18.7 2.1 67.6 2.9 87.5 1.5 111.3-8.1 209.9-47.9 295.6-119.2 15.9-13.3 48.5-45.9 61.8-61.8 71.3-85.7 111.1-184.3 119.2-295.6 1.4-19.9.6-68.8-1.5-87.5-11.8-105-50.7-196.8-117.4-277-12.6-15.1-47.6-50-62.7-62.6-33.9-28.1-64.9-48.2-104-67.4-55-27-105.9-41.8-171-49.6-15.1-1.9-78.9-2.8-95-1.4zM556 45.6c203.1 18.6 372.7 170.1 414.4 370.1 7.7 37.1 10.1 63.6 9.3 105.6-.7 40.5-4.1 67-13.3 104-8.7 34.7-19 62.6-35 94.7-23.8 47.7-51.2 85.9-88.4 123-37.1 37.2-75.3 64.6-123 88.4-49 24.4-98.3 39-155.5 46.2-17.5 2.2-68.7 3-88.2 1.5-130.3-10.4-248.4-72.8-330-174.6C18.4 645 9 420.2 123.2 250.8 205.5 128.6 338.9 52.6 486 44.1c19.1-1.1 48.1-.5 70 1.5z" />-->

      <path
        d="M0,512 a1,1 0 0,0 1024,0"
        stroke-width="4.5%"
        stroke="black"
        stroke-linecap="round"
        fill="transparent"
        style="transform: scale(0.92) translateX(4%) translateY(4%)"
      />
      <path
        d="M510 129.2c0 2.1-5.4 37.3-7 46.1-3.7 19.4-11.6 37.9-24.6 57.3-3.5 5.3-6.4 9.8-6.4 10 0 .2 1.9.4 4.1.4 6.4 0 12 2.1 15.9 6 8 8 8.1 23.8.1 35.3-1.7 2.5-3.1 5.3-3.1 6.3s1.2 4.7 2.6 8.3c4.2 10.4 5.3 20.8 6 55.6.9 43.5-1 66.3-7.7 93.5-3.7 14.9-9.3 29.9-14.9 39.4-3.9 6.7-4.3 8.6-1.5 8.6 3.5 0 10.6 12.2 13 22.2 3.9 16.5.8 30-11.9 53.2-2.7 5-4.4 9.2-3.9 9.3 3.7 1.3 11 7.3 13.4 11.2 3.8 5.9 4.4 10 3 18.9-1.5 10.1-4.4 16.8-14.5 33.5-13.1 21.9-19.7 39.4-21.1 55.9-1.2 14 3.1 24.9 22.4 56.6 19.4 31.9 24.5 44.2 28 67.8 1.8 11.9 8.1 65.4 8.1 68.6 0 .6.2.9.4.7.3-.3 1.4-9.2 2.6-19.9 3-27.7 5.8-49.9 7.6-59.2 3-16.1 10.8-32.9 27.8-60.3 18.4-29.6 22.7-41.7 20.6-58-1.9-15.6-10.2-36-22.1-54.5-6.7-10.5-10.7-19.4-12.5-28.1-2.1-9.7-1.4-15.7 2.2-21.6 2.6-4.1 9.7-10.2 13.5-11.5.8-.3-.6-3.7-3.8-9.5-12.4-21.9-15.7-36.6-11.8-53.2 2.5-10.5 9.4-22.1 13.1-22.1 2.4 0 2.5-2 .4-5-6.2-8.7-14.2-30-18.4-49-5.2-23.7-6.1-34.2-6-76.5 0-41.4.8-53.3 4.5-63.4 4.6-12.3 4.6-12.3.9-17.6-4-5.8-6-12.1-6-18.8 0-14 8.4-22.7 21.9-22.7 2.2 0 4.1-.1 4.1-.3 0-.2-3-4.8-6.6-10.2-11.6-17.4-20.4-37.2-23.8-53.7-.8-4-2.9-16.7-4.6-28.2-1.7-11.6-3.3-21.2-3.5-21.5-.3-.2-.5-.2-.5.1z"
      />
      <g class="spin">
        <path
          d="M650.1 505.6c-1.7 10.3-7.4 21.4-15.1 29.2-3.6 3.7-12 10.2-20.8 15.9-30 19.6-40.2 34.8-43.1 64.2-.5 5.3-.6 11.3-.3 13.5l.7 3.9 3.2-6.9c6.2-13.5 11.5-17.7 34.5-27.4 16.3-6.8 21.3-10 25.9-16.3 7.2-9.6 13.7-27.9 16.4-45.7 1.6-10.2 2-36.5.7-37.4-.5-.3-1.4 2.9-2.1 7z"
        />
        <path
          d="M368 513.2c0 22 3.8 40.9 11.5 57.3 4.1 8.9 8.4 14.7 13.6 18.4 2.2 1.6 11 5.9 19.6 9.6 22.5 9.5 26.2 12.5 33.6 26.7l3.7 7.3-.4-13.5c-.5-15.5-2.6-24.3-8.3-35.3-5.8-11.1-14.3-19.2-33.1-31.9-9.6-6.4-19.2-13.7-22.5-17.1-6.9-7.2-13.1-19.2-14.9-29-.7-3.7-1.6-6.7-2-6.7-.5 0-.8 6.4-.8 14.2z"
        />
        <path
          d="M380 642.7c0 2.5 7.2 12.2 11.7 15.9 7.2 5.9 14.3 8.3 27 9.1 6.9.4 11.7 1.2 13.9 2.4 3.5 1.7 3.5 1.7 2.9-.4-.4-1.2-1.8-4.6-3.2-7.5-3.9-8.3-12.5-12-31.3-13.7-9.4-.8-14.7-2.2-18.2-4.7-1.6-1.1-2.8-1.5-2.8-1.1z"
        />
        <path
          d="M639.6 642.8c-1.4 2.3-8.5 4.4-19.5 5.7-21.2 2.5-27.8 5.4-31.6 13.9-1.3 2.8-2.6 6.1-2.9 7.3l-.7 2.1 3.8-1.9c2.3-1.2 5.8-1.9 9.3-1.9 15.2-.1 25.9-3.8 33.9-11.8 4.6-4.6 9.8-12.8 8.8-13.9-.3-.2-.8 0-1.1.5z"
        />
      </g>
    </svg>
  </div>
  <div class="col-7">
    <p translate>MODALS.CONSTRUCTION.TEXT</p>
    <h3 translate class="text-center text-bold pt-7 pb-7">
      MODALS.CONSTRUCTION.SOON
    </h3>
  </div>
</div>
