import { Component, OnInit } from "@angular/core";
import { ModalService } from "src/app/services/modal.service";

@Component({
  selector: "app-construction",
  templateUrl: "./construction.component.html",
  styleUrls: ["./construction.component.scss"],
})
export class ConstructionComponent implements OnInit {
  constructor(private modalService: ModalService) {
    this.modalService.setTitle("MODALS.CONSTRUCTION.TITLE");
    this.modalService.setCanBeClosed(false);
  }

  ngOnInit(): void {}
}
