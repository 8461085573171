<div [attr.class]=getFilling(this.fill)>
    <div class="circle">
        <div class="mask" [style.fill]=this.color>
            <svg  class="fit bright" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" stroke="none">
                <path class="speed-2" d="M0 100L.06 4.4C3 2.4 6 .5 7.47 2c3.86 3.88 6.55 1.88 9.93.67a4.11 4.11 0 0 1 2.82.24c4.2 1.9 6.78.65 10.6-1.9A5.55 5.55 0 0 1 37 .9c4.1 2.22 4.88 4.6 9.14 1.8a5.75 5.75 0 0 1 7.75.65C57 6.5 60.36 6.9 64.05 4.4v100" />
            </svg>
            <svg class="fit" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" stroke="none">
                <path class="speed-2" d="M0 100L.06 4.4C3 2.4 6 .5 7.47 2c3.86 3.88 6.55 1.88 9.93.67a4.11 4.11 0 0 1 2.82.24c4.2 1.9 6.78.65 10.6-1.9A5.55 5.55 0 0 1 37 .9c4.1 2.22 4.88 4.6 9.14 1.8a5.75 5.75 0 0 1 7.75.65C57 6.5 60.36 6.9 64.05 4.4v100" />
            </svg>
        </div>
        
        <div class="content">
            <ng-content select="[icon]"></ng-content>
            <!--<img [attr.src]=this.image />-->
        </div>
    </div>
    <div class="text">
        <div class="title">
            <ng-content select="[title]"></ng-content>
        </div>
        <div class="description">
            <ng-content select="[description]"></ng-content>
        </div>
    </div>
</div>