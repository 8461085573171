import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LevelbarComponent } from "./components/levelbar/levelbar.component";
import { HomeComponent } from "./pages/home/home.component";
import { GamesComponent } from "./pages/games/games.component";
import { AppsComponent } from "./pages/apps/apps.component";
import { FormationComponent } from "./pages/formation/formation.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";
import { AboutComponent } from "./pages/about/about.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { PaletteComponent } from "./components/palette/palette.component";
import { ProductComponent } from "./components/product/product.component";
import { ConstructionComponent } from './modals/construction/construction.component';
import { ModalComponent } from './components/modal/modal.component';
//import { HttpClient } from 'selenium-webdriver/http';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    LevelbarComponent,
    HomeComponent,
    GamesComponent,
    AppsComponent,
    FormationComponent,
    MaintenanceComponent,
    AboutComponent,
    ContactComponent,
    PaletteComponent,
    ProductComponent,
    ConstructionComponent,
    ModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
