import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-levelbar',
  templateUrl: './levelbar.component.html',
  styleUrls: ['./levelbar.component.scss']
})
export class LevelbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
