export class Cookie {
    public theme: string = "";
    public lang: string = "";
    public sess: string = "";
	
    constructor(theme: string) {
        this.theme = theme;
	}
	
}
