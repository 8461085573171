<div id="carouselExampleIndicators" class="carousel slide">
  <ol class="carousel-indicators">
    <li
      data-target="#carouselExampleIndicators"
      data-slide-to="0"
      class="active"
    ></li>
    <!--
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>-->
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/images/header/home.png" width="100%" />
      <div class="col-5 parallax position-absolute">
        <div data-depth="0.04" class="position-relative">
          <h1 translate>HOME.SLOGAN.0</h1>
        </div>
        <div data-depth="0.09" class="position-relative text-center">
          <h1 translate>HOME.SLOGAN.1</h1>
        </div>
        <div data-depth="0.15" class="position-relative image">
          <img src="assets/images/header/zeppelin.png" />
        </div>
      </div>
    </div>
    <!--
    <div class="carousel-item">
      <img src="assets/images/header/home.png" width="100%"/>
    </div>
    <div class="carousel-item">
      <img src="assets/images/header/home.png" width="100%"/>
    </div>-->
  </div>
</div>

<div class="container">
  <div class="row mt-2">
    <div class="col-9">
      <h1 translate class="text-center">HOME.CONTENT.PARAGRAPH-1.TITLE</h1>
      <p [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.CONTENT' | translate"></p>
    </div>
  </div>
</div>

<div class="row position-relative mt-1 mb-2 mr-0 ml-0">
  <div class="gradient d-flex w-100">
    <div class="w-100 d-flex">
      <div class="col-3 d-flex">
        <img
          src="assets/images/website/home-hexa.png"
          alt="marcas"
          class="scale-6 m-auto mvw-50 pl-container"
          data-aos="fade-right"
          data-aos-once="true"
          data-aos-offset="200"
        />
      </div>
      <div class="col-9 panel pr-container">
        <div class="pt-2 pb-2">
          <h1 translate class="text-center">HOME.CONTENT.PARAGRAPH-1.TITLE</h1>
          <p [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.CONTENT' | translate"></p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-9">
      <h1 translate class="text-center">HOME.CONTENT.PARAGRAPH-1.TITLE</h1>
      <p [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.CONTENT' | translate"></p>
    </div>
  </div>
  <div class="row mt-1 text-center">
    <product
      [color]="'var(--product-colorA)'"
      [fill]="33"
      [image]="'assets/images/website/icons/design.png'"
      class="col-md-4 pt-2 pb-2"
      data-aos="fade"
      data-aos-once="true"
    >
      <svg
        icon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 418.8 415.6"
        fill="var(--product-colorA)"
        stroke="var(--product-colorA)"
        class="fit scale-3"
      >
        <path
          fill="none"
          stroke-width="20"
          d="M370.1 276.4v88.7c0 22.1-17.9 39.9-39.9 39.9H49.9c-22 0-39.9-17.8-39.9-39.9V104.9C10 82.8 27.9 65 49.9 65h88.7m237.5-35.8c-17.6-17.6-46-17.6-63.6 0L134.4 207.4a10.32 10.32 0 0 0-2.6 4.4l-23.4 84.6c-1 3.5 0 7.2 2.6 9.7 2.5 2.5 6.3 3.5 9.7 2.6l84.6-23.4c1.7-.5 3.2-1.3 4.4-2.6l178.2-178.2c17.5-17.6 17.5-46 0-63.6l-11.8-11.7z"
        />
        <path
          stroke="none"
          d="M156.146 213.855L301.95 68.05l47.022 47.022-145.804 145.804zM146.8 232.8l37.5 37.5-51.9 14.4zM373.7 90.4L363.1 101l-47-47 10.6-10.6c9.7-9.7 25.6-9.7 35.3 0l11.7 11.7c9.8 9.7 9.8 25.5 0 35.3z"
        />
      </svg>
      <h2
        title
        [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.PASO-1.TITLE' | translate"
      ></h2>
      <p
        description
        [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.PASO-1.TEXT' | translate"
      ></p>
    </product>
    <product
      [color]="'var(--product-colorB)'"
      [fill]="66"
      [image]="'assets/images/website/icons/develop.png'"
      class="col-md-4 pt-2 pb-2"
      data-aos="fade"
      data-aos-delay="250"
      data-aos-once="true"
    >
      <svg
        icon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 568.1 574.9"
        fill="var(--product-colorB)"
        stroke="var(--product-colorB)"
        class="fit scale-3"
      >
        <path
          fill="none"
          d="M520.7 414.3L314.1 207.8c4.1-13.7 6.3-28.4 6.3-42.4 0-37-15-73.7-41.3-100.6l-.1-.1C240.2 25 181.4 12.4 129.3 32.4c-6.4 2.5-11.1 8-12.5 14.7s.7 13.6 5.6 18.4l46.8 46.2-10.9 32.4-29.6 10.1-46.8-48c-4.7-4.9-11.6-7-18.3-5.7s-12.2 5.9-14.8 12.1c-7.9 19.3-11.6 36-11.6 52.6 0 37 15 73.7 41.3 100.6 26.2 26.9 62.8 42.3 100.4 42.3 10.8 0 21.4-1.2 31.7-3.6l210.1 210.1c13.9 13.5 32 20.2 50.1 20.2 18.3 0 36.5-6.9 50.2-20.7 27-27.7 26.9-72.6-.3-99.8h0z"
          stroke-width="25"
        />
        <path
          stroke="none"
          d="M470.7 505c-10.9 0-21.2-4.1-29.1-11.6L219.9 271.8l-16.2 3.8c-8.1 1.9-16.5 2.8-24.9 2.8-29.6 0-58.3-12.1-78.9-33.3-20.8-21.4-32.8-50.5-32.8-79.7 0-8.5 1.4-17.2 4.2-26.8l49 50.2 61.5-21 21.7-64.1-48.4-47.8c8.1-1.8 16.3-2.8 24.6-2.8 29.6 0 57.3 11.6 77.9 32.6l.1.1c20.8 21.4 32.7 50.4 32.7 79.6 0 11-1.8 22.7-5.1 33.7l-5.2 17.2 219.2 219.2c15.6 15.6 15.6 41.5.1 57.8-7.5 7.6-17.8 11.7-28.7 11.7z"
        />
      </svg>
      <h2
        title
        [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.PASO-2.TITLE' | translate"
      ></h2>
      <p
        description
        [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.PASO-2.TEXT' | translate"
      ></p>
    </product>
    <product
      [color]="'var(--product-colorC)'"
      [fill]="100"
      [image]="'assets/images/website/icons/test.png'"
      class="col-md-4 pt-2 pb-2"
      data-aos="fade"
      data-aos-delay="500"
      data-aos-once="true"
    >
      <svg
        icon
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511 528"
        stroke-width="24"
        fill="var(--product-colorC)"
        stroke="var(--product-colorC)"
        class="fit scale-3"
      >
        <path
          fill="none"
          d="M247.1 121.7c-62.5 7-112.3 61.9-112.3 127.2v131.5c0 70.7 56.7 129.2 127.4 129.6 71 .4 128.9-57.2 128.9-128.2V248.9c0-65.3-49-119.3-112.3-127.2-13.3-1.6-15.9-1.7-31.7 0z"
        />
        <path
          fill="none"
          d="M262.9 120.5v-16.1c0-28.3 23-51.3 51.3-51.3s51.3-23 51.3-51.3"
        />
        <path
          stroke="none"
          d="M359.4 248.9v6.8h-80.6v-102c45.6 7.7 80.6 47.5 80.6 95.2zm-112.3-95.1v102h-80.6V249c0-47.8 34.9-87.6 80.6-95.2zm15.8 324.5c-53.2 0-96.5-43.3-96.5-96.5v-94.3h192.9v94.3c.1 53.2-43.2 96.5-96.4 96.5z"
        />
      </svg>
      <h2
        title
        [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.PASO-3.TITLE' | translate"
      ></h2>
      <p
        description
        [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.PASO-3.TEXT' | translate"
      ></p>
    </product>
    <!--
    <div class="col-md-4 product arrow right">
      <img src="assets/images/website/icons/design.png"/>
      <p ></p>
    </div>
    <div class="col-md-4 product arrow right">
      <img src="assets/images/website/icons/develop.png" />
      <p [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.PASO-2' | translate"></p>
    </div>
    <div class="col-md-4 product arrow right only-mobile">
      <img src="assets/images/website/icons/test.png" />
      <p [innerHTML]="'HOME.CONTENT.PARAGRAPH-1.PASO-3' | translate"></p>
    </div>-->
  </div>

  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
  </div>
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
  </div>
</div>
