import { Component, NgModule, HostBinding } from '@angular/core';
import { ControlSystemService } from './services/control-system.service';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
    title = 'intare';
    constructor(private controlSystem: ControlSystemService) {
      window.addEventListener('load', AOS.init);
    }
    
}
