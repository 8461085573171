import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutComponent } from "./pages/about/about.component";
import { AppsComponent } from "./pages/apps/apps.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { FormationComponent } from "./pages/formation/formation.component";
import { GamesComponent } from "./pages/games/games.component";
import { HomeComponent } from "./pages/home/home.component";
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "development/apps", component: AppsComponent },
  { path: "development/software", component: FormationComponent },
  { path: "development/games", component: GamesComponent },
  { path: "development/web", component: MaintenanceComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
